import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Leaderboard from "./Pages/Leaderboard";
import Player from "./Pages/Player";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lb" element={<Leaderboard />} />
        <Route path="/player" element={<Player />} /> 
        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;