import { useSearchParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react'
import Topbar from '../Components/Topbar';

function Player() {
    const [searchParams] = useSearchParams();
    const playerName = searchParams.get('name')

    const [scoresData, setScores] = useState([])
    const [error, setError] = useState(null)


    const hasFetched = useRef(false)
    useEffect(() => {
        if (!hasFetched.current) {
            const fetchData = async () => {
                try {
                    const response = await fetch('api/player?playername=' + playerName)
                    const data = await response.json()
                    if (data.scores) {
                        setScores((data.scores).sort((a, b) => b.pp - a.pp))
                    } else {
                        setError("Failed to load scores")
                    }
                } catch (error) {
                    setError("Failed to find player")
                }
            };
            fetchData()
            hasFetched.current = true;
        }
    }, [])

    if (error) {
        return (
            <div>
                <Topbar />
                <div className="scores max-w-4xl mx-auto p-4">
                    <h1 className="text-4xl mb-8">{playerName}</h1>
                    <p className="text-red-500">{error}</p>
                </div>
            </div>
        )
    }

    if (!scoresData.length) {
        return (
            <div>
                <Topbar />
                <div className="scores max-w-4xl mx-auto p-4">
                    <h1 className="text-4xl mb-8">{playerName}</h1>
                    <p>Loading...</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Topbar />
            <div className="scores max-w-4xl mx-auto p-4">
                <h1 className="text-4xl mb-8">{playerName}</h1>
                <table className="w-full table-auto border-2 border-zinc-300">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-4 py-2 text-left">Map</th>
                            <th className="px-4 py-2 text-left">Score</th>
                            <th className="px-4 py-2 text-left">Accuracy</th>
                            <th className="px-4 py-2 text-left">PP</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scoresData.map((score, index) => (
                            <tr key={index} className="border-b border-zinc-200 hover:bg-gray-200">
                                <td className="px-4 py-2">{score.map_name}</td>
                                <td className="px-4 py-2">{score.score}</td>
                                <td className="px-4 py-2">{score.acc}%</td>
                                <td className="px-4 py-2">{score.pp}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Player;