import React from 'react'

function Topbar() {
  const handleClick = async () => {
    const response = await fetch('api/players'); 
    const data = await response.json();
    console.log(data);
  }

  const handleClick2 = async () => {
    const response = await fetch('api/maps'); 
    const data = await response.json();
    console.log(data);
  }

  return (
    <div className="topbar bg-zinc-800 py-4">
      <div className="container mx-auto flex justify-between items-center">
          <ul className="flex">
            <li className="mr-4">
              <a href="lb" className="text-white hover:text-gray-300 text-xl pl-4 ">leaderboard</a>
            </li>
          </ul>
      </div>
    </div>
  )
}

export default Topbar