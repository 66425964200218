import React from 'react';

const Home = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <h1 className="text-5xl font-bold text-gray-500">Folou gaming 2024</h1>
    </div>
  );
};

export default Home;