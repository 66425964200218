import React, { useState, useEffect, useRef } from 'react'
import Topbar from '../Components/Topbar'
import { Link } from 'react-router-dom';

function Leaderboard() {
    const [currentPage, setCurrentPage] = useState(1)
    const [playersPerPage, setPlayersPerPage] = useState(20)
    const [sortedLeaderboard, setSortedLeaderboard] = useState([])
    const [leaderboardData, setLeaderboardData] = useState({})

    const hasFetched = useRef(false);
    useEffect(() => {
        if (!hasFetched.current) {
            const fetchData = async () => {
                const response = await fetch('api/leaderboard');
                const jsonData = await response.json();
                setLeaderboardData(jsonData);
            };
            fetchData();
            hasFetched.current = true;
        }
    }, []);

    useEffect(() => {
        if (Object.keys(leaderboardData).length > 0) {
            const sortedData = Object.values(leaderboardData._default).sort((a, b) => b.total_pp - a.total_pp)
            setSortedLeaderboard(sortedData)
        }
    }, [leaderboardData])

    const indexOfLastPlayer = currentPage * playersPerPage
    const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage
    const currentPlayers = sortedLeaderboard.slice(indexOfFirstPlayer, indexOfLastPlayer)

    const paginate = pageNumber => setCurrentPage(pageNumber)
    
    return (
        <div>
            <Topbar />
            <div className="leaderboard  max-w-4xl mx-auto p-4">
                  <table className="w-full table-auto border-2 border-zinc-300">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-4 py-2 text-left">Rank</th>
                            <th className="px-4 py-2 text-left">Name</th>
                            <th className="px-4 py-2 text-left">Total PP</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPlayers.map((player, index) => (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="px-4 py-2">{indexOfFirstPlayer + index + 1}</td>
                                <td className="px-4 py-2">
                                    <Link to={`/player?name=${player.name}`}>{player.name}</Link>
                                </td>
                                <td className="px-4 py-2">{player.total_pp}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="pagination flex justify-center mt-4">
                    <button
                        className={`px-4 py-2 text-gray-700 border border-gray-300 rounded ${currentPage === 1 ? 'bg-gray-200' : ''}`}
                        onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                    >
                        Prev
                    </button>
                    <input
                        type="number"
                        value={currentPage}
                        onChange={(e) => paginate(Math.min(Math.max(1, parseInt(e.target.value)), Math.ceil(sortedLeaderboard.length / playersPerPage)))}
                        className="px-4 py-2 text-gray-700 border border-gray-300 rounded w-20 appearance-none text-center"
                        style={{ MozAppearance: 'textfield' }}
                    />
                    <button
                        className={`px-4 py-2 text-gray-700 border border-gray-300 rounded ${currentPage === Math.ceil(sortedLeaderboard.length / playersPerPage) ? 'bg-gray-200' : ''}`}
                        onClick={() => currentPage < Math.ceil(sortedLeaderboard.length / playersPerPage) && paginate(currentPage + 1)}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Leaderboard